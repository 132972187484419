@import 'styles/breakpoints';

$house-offset: -200px;
$house-height: 500px;

.dark {
  background-color: var(--bg-layer);
}

.hero {
  position: relative;
  height: clamp(440px, 60vh, 50vw);

  @include from($widthTablet) {
    height: clamp(600px, 60vh, 50vw);
    padding-top: 60px;
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 66%;
    z-index: 2;

    @include until($widthTablet) {
      height: 50%;
    }

    h1.title {
      display: inline-block;
      font-size: 64px; // only used for h1 on Home
      line-height: 1.2;
      color: var(--white);

      @include until($widthTablet) {
        padding-left: var(--spacing-400);
        font-size: 32px;
        line-height: 1.2;
      }

      & > span {
        color: var(--text-primary);
      }

      &.fr {
        margin-top: var(--spacing-900);
      }
    }
  }

  .hero-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--lightblue-300);

    &:before {
      content: '';
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}

.intro {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--spacing-600);
  row-gap: var(--spacing-400);
  align-items: center;
  padding-top: 400px;

  @include from($widthMobile) {
    padding-top: 350px;
  }

  @include from($widthTablet) {
    padding-top: 200px;
    grid-template-columns: 1fr 1fr;
  }

  p {
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);
  }

  .press-testimonials-container {
    margin-top: var(--spacing-700);

    @include until($widthMobile) {
      margin-top: var(--spacing-200);
    }
  }
}

.cta-block {
  .cta-image {
    aspect-ratio: 2/1;
    height: 315px;
    width: 100%;

    img {
      object-position: center 75%;
    }

    @include from($widthTablet) {
      height: 100%;
    }
  }
}

.featured {
  margin-top: var(--spacing-700);
}

.readable {
  max-width: 860px;
}

p.subtitle {
  color: var(--text-default);
  font-weight: normal;
  margin: var(--spacing-400) 0;
}
