@import 'styles/breakpoints';

.price {
  .bubble {
    background-color: var(--accent-yellow);
    border-radius: 43px;
    display: flex;
    height: 48px;
    width: 96px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: var(--spacing-400);
    top: -36px;

    &.with-promo-code {
      height: 60px;
    }
  }

  .text {
    color: var(--text-default);
    font-weight: bold;
    font-size: var(--text-xs-font-size);
    line-height: var(--text-xs-line-height);
    text-align: center;
    margin: var(--spacing-200) 0;
  }

  .number {
    display: inline-block;
    margin-bottom: 3px;

    &.is-without-promo-code {
      color: red;
      font-size: 11px;
      line-height: 1;
      font-weight: normal;
      text-decoration: line-through;
    }
  }

  .per-month {
    font-weight: lighter;
    font-size: 11px;
    text-transform: lowercase;
  }
}
