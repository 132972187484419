@import 'styles/breakpoints';

.producer-card {
  display: flex;
  flex-direction: column;
  color: var(--text-default);
  height: 100%;

  .inner {
    cursor: pointer;
    touch-action: manipulation;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-sm);
    transition: all var(--transition-speed) ease-in-out;
    height: 100%;
    background-color: var(--white);
    z-index: 1;
    position: relative;
    overflow: hidden;
    box-shadow: var(--shadow-sm);

    .photo {
      position: relative;
      height: 174px;
      border-top-left-radius: var(--radius-sm);
      border-top-right-radius: var(--radius-sm);
      overflow: hidden;
      background-color: var(--bg-layer);
      width: 100%;

      .sold-out,
      .reserved,
      .popular {
        position: absolute;
        right: var(--spacing-400);
        top: var(--spacing-400);
        transition: all var(--transition-speed) ease-in-out;
        box-shadow: var(--shadow-sm);
        padding: var(--spacing-200) var(--spacing-300);
        font-size: var(--text-sm-font-size);
        line-height: var(--text-sm-line-height);
      }

      .reserved {
        background-color: var(--bg-layer);
        color: var(--text-default);

        &:hover {
          background-color: var(--bg-layer-hover);
        }
      }

      .sold-out {
        background-color: var(--blue-800);
        color: var(--neutral-300);

        &:hover {
          background-color: var(--blue-800);
          color: var(--neutral-300);
        }
      }

      .popular {
        background: linear-gradient(-270deg, #55e1c9 0%, #46f9c0 100%);
        color: #141a57;

        &:hover {
          background: linear-gradient(-270deg, #55e1c9 0%, #46f9c0 100%);
          color: #141a57;
        }
      }
    }

    .producer-content {
      position: relative;
      padding: 0 var(--spacing-400) var(--spacing-400);
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      flex-grow: 1;

      .producer-location-container {
        margin-top: var(--spacing-100);
      }

      .name {
        font-size: var(--heading-xs-font-size);
        line-height: var(--heading-xs-line-height);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        font-size: var(--text-sm-font-size);
        line-height: var(--text-sm-line-height);
        color: var(--text-default);
        opacity: 0.6;
        user-select: none;
        margin-bottom: var(--spacing-100);
      }

      .read-more {
        text-align: left;
        margin-bottom: var(--spacing-200);
      }
    }

    &:hover .photo .read-more,
    &:focus .photo .read-more {
      visibility: visible;
      bottom: var(--spacing-400);
      opacity: 1;
    }
  }

  &:hover .inner,
  &.is-active .inner {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }

  .stats {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .progress {
    color: var(--text-default);
    font-size: var(--text-xs-font-size);
  }
}
