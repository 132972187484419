@import 'styles/breakpoints';

.root {
  --sticky-offset: 90px;

  &.scrollingDown {
    --sticky-offset: 16px;
  }

  overflow: unset;
  padding-bottom: calc(var(--spacing-500) + var(--js-root-offset, 0em));
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);

  @media screen and (min-width: $widthTablet) {
    padding-bottom: calc(var(--spacing-800) + var(--js-root-offset, 0em)); // 120px + offset of last card
    gap: var(--spacing-600);
  }
}

.header {
  position: sticky;
  top: var(--sticky-offset);
  transition: top var(--transition-speed);
}

.cards {
  display: grid;
  row-gap: var(--spacing-400);
  padding: 0;
  list-style-type: none;
  grid-auto-rows: 1fr;

  @media screen and (min-width: $widthTablet) {
    row-gap: var(--spacing-600);
  }
}
