@import 'styles/breakpoints';

.showcase-wrapper {
  display: flex;
  padding: var(--spacing-100);
  margin: var(--spacing-500) 0;
  gap: var(--spacing-500);
  overflow-x: auto;

  @include until($widthMobile) {
    > div {
      width: 310px;
    }
  }

  @include from($widthMobile) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include from($widthDesktop) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
