@import 'styles/breakpoints';

.wrapper {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
  height: 40px;

  &.boxed {
    background-color: var(--white);
    border-radius: var(--radius-sm);
    box-shadow: var(--shadow-sm);
    padding-left: var(--spacing-300);
    padding-right: var(--spacing-300);
  }

  .energy {
    background-color: #e5e9f7;
    color: #15225e;
    padding: 1px var(--spacing-100);
    font-size: 11px;
    font-weight: normal;
    border-radius: var(--radius-sm);
  }

  .location {
    color: var(--text-default);
    font-size: var(--text-xs-font-size);
    line-height: var(--text-xs-line-height);
    font-weight: normal;
    opacity: 0.6;
  }
}
