@import 'styles/breakpoints';

.wrapper {
  width: 90%;
  max-width: 1030px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-500);

  &:not(.no-overlay) {
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    transform: translateY(-33%);
  }

  @include until($widthTablet) {
    width: 95%;
    max-width: none;
  }
}

.cta {
  position: relative;
  padding: var(--spacing-500);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  border-radius: var(--radius-sm);

  > form {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 1.5fr;
    grid-auto-rows: minmax(50px, auto);
    gap: var(--spacing-400);
    font-size: var(--text-sm-font-size);

    @include until($widthTablet) {
      grid-template-columns: 1fr;
    }

    input,
    select {
      height: 100%;
      width: 100%;
    }

    .prepended-select {
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        position: absolute;
        display: flex;
        width: 20px;
        z-index: 2;
        color: var(--text-primary);
        left: 14px;
      }

      select {
        padding-left: 48px;
        width: 100%;
      }
    }

    .simulate-button {
      @include until($widthTablet) {
        min-height: 50px;
      }
    }
  }
}

section.contact {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-300);
  z-index: 99;
  padding: var(--spacing-400) var(--spacing-500);
  background-color: rgba(255, 255, 255, 0.50);
  text-align: center;

  @include from($widthTablet) {
    text-align: left;
    flex-direction: row;
  }

  .heroes {
    display: flex;
    flex-shrink: 0;
    transform: translateX(calc((-5 * 8px) / 2)); // Amount of children * offset / 2 to center

    @include from($widthTablet) {
      transform: unset;
      margin-left: auto;
    }

    img {
      border-radius: 50%;
      border: 2px solid var(--white);

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          transform: translateX(#{(5 - $i) * 8}px);
        }
      }
    }
  }
}
