@import 'styles/breakpoints';

.sk-three-bounce {
  $animationDuration: 1.4s;
  $delayRange: 0.32s;
  $spinkit-spinner-margin: 0px auto;
  $spinkit-size: 20px;
  $spinkit-spinner-color: var(--blue-500);
  margin: $spinkit-spinner-margin;
  width: $spinkit-size * 2;
  text-align: center;

  .sk-child {
    width: calc(#{$spinkit-size} / 2);
    height: calc(#{$spinkit-size} / 2);
    background-color: $spinkit-spinner-color;

    border-radius: 100%;
    display: inline-block;
    animation: sk-three-bounce $animationDuration ease-in-out 0s infinite both;
  }

  .sk-bounce1 {
    animation-delay: -$delayRange;
  }

  .sk-bounce2 {
    animation-delay: calc(#{-$delayRange} / 2);
  }
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
