@import 'breakpoints';

.blockquote {
	height: 100%;
	width: 100%;
	padding: 3vw 6vw;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: var(--spacing-500);

	@include until($widthTablet) {
		padding: var(--spacing-600);
	}
}

.quote {
	font-weight: bold;
	font-size: var(--text-md-font-size);
	line-height: var(--text-md-line-height);

	@include from($widthDesktop) {
		font-size: var(--text-lg-font-size);
		line-height: var(--text-lg-line-height);
	}

	@include from($widthDesktopLarge) {
		font-size: var(--heading-sm-font-size);
	}
}

.footer {
	display: flex;
	flex-direction: column;

	// logo
	svg {
		height: 36px;
	}
}

.cite {
	font-size: var(--text-md-font-size);
	line-height: var(--text-md-line-height);
	font-weight: normal;
}

.extra {
	font-size: var(--text-sm-font-size);
	line-height: var(--text-sm-line-height);
}