@import 'styles/breakpoints';

.section {
  min-height: clamp(640px, calc(100vh - 80px), 840px);
  display: flex;

  @include until($widthTablet) {
    height: auto;
    flex-direction: column-reverse;
    padding: 0 0 var(--spacing-600);
  }

  :global(.container) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-800);
    height: 100%;
    align-self: center;

    @include until($widthDesktop) {
      width: 100%;
    }

    @include until($widthTablet) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding-top: var(--spacing-600);
    }

    .left-content {
      max-width: 551px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-500);

      @include until($widthTablet) {
        max-width: unset;
      }
    }
  }

  .right-content {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;

    @include until($widthTablet) {
      grid-row: 1;
      position: relative;
      width: 100%;
      height: 375px;
    }
  }
}
