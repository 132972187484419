@import 'styles/breakpoints';

.root {
  padding: var(--spacing-600);
  grid-template-columns: 1fr;
  top: calc(var(--sticky-offset) + #{var(--spacing-400)} + var(--js-header-height, 0px)); // offset + gap + spacing taken by title
  transition: top var(--transition-speed);
  margin: 0;
  position: sticky;
  transform-origin: center top;
  align-items: center;
  justify-content: center;
  display: grid;
  gap: var(--spacing-600);
  transform: translateY(var(--js-translate-Y, 0em));

  @include from($widthTablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include from($widthDesktopLarge) {
    padding: var(--spacing-800);
    gap: var(--spacing-800);
  }

  &:not(:first-child) {
    box-shadow: 0px -16px 28px 0px rgba(9, 30, 66, 0.1);
  }

  &:nth-child(2n) {
    grid-auto-flow: dense;
    direction: rtl;

    .content {
      direction: ltr;
    }
  }

  &.yellow {
    background-color: var(--accent-yellow);

    .content {
      svg {
        color: var(--accent-yellow-subdued);
      }
    }
  }

  &.purple {
    background-color: var(--accent-purple);

    .content {
      svg {
        color: var(--accent-purple-subdued);
      }
    }
  }

  &.blue {
    background-color: var(--accent-lightblue);

    .content {
      svg {
        color: var(--accent-lightblue-subdued);
      }
    }
  }

  &.orange {
    background-color: var(--accent-orange);

    .content {
      svg {
        color: var(--accent-orange-subdued);
      }
    }
  }

  .content {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
    direction: ltr;

    .order {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      position: relative;
      margin-right: auto;

      > svg {
        height: 68px;
      }

      strong {
        position: absolute;
        font-size: var(--heading-md-font-size);
        margin-top: 14px;
      }
    }

    h3 {
      font-size: var(--heading-md-font-size);
      line-height: var(--heading-md-line-height);
    }

    p {
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
    }
  }

  picture {
    display: none;

    @include from($widthTablet) {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }

    &.house {
      width: 100%;
      height: 410px;
      mask-image: url('/images/masks/house-mask.svg');
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &.overflowing {
      position: absolute;
      width: 40%;
      left: 0;
    }
  }
}
