@import 'styles/breakpoints';

.encyclopedia-navigation-menu {
  a {
    color: var(--text-default);
    text-decoration: none;
  }

  h2 {
    margin-bottom: calc(var(--spacing-500) + var(--spacing-200));

    @include until($widthMobile) {
      margin-bottom: var(--spacing-500);
      margin-top: var(--spacing-400);
    }
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    gap: var(--spacing-500);

    .category-group {
      h3 {
        margin-bottom: var(--spacing-400);
        text-decoration: underline solid 3px;
      }

      .sub-category-group {
        .link {
          display: flex;
          gap: var(--spacing-400);
          padding: var(--spacing-400);
          padding-left: var(--spacing-100);
          justify-content: space-between;
          align-items: center;
          font-size: var(--text-sm-font-size);
          line-height: var(--text-sm-line-height);
          border-bottom: 1px solid var(--border-subdued);

          @include until($widthMobile) {
            padding: var(--spacing-300);
            padding-left: var(--spacing-100);
          }

          &:hover {
            svg {
              color: var(--text-default);
              opacity: 1;
            }
          }

          &.pink {
            &:hover {
              background-color: #fff6fd;
            }
          }

          &.orange {
            &:hover {
              background-color: #fff9f2;
            }
          }

          &.green {
            &:hover {
              background-color: #eefff7;
            }
          }

          svg {
            opacity: 0.15;
          }
        }
      }
    }
  }
}
