@import 'styles/breakpoints';
@import 'styles/mixins';

.become-producer {
  flex-direction: column;
  padding-bottom: 0;
  gap: var(--spacing-500);

  @include from($widthTablet) {
    flex-direction: row;
  }

  .become-producer-left {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-500);

    h2 {
      margin-bottom: 0;
    }

    p {
      max-width: 860px;
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
    }

    a {
      width: fit-content;
    }
  }

  .become-producer-right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    height: auto;

    @include from($widthTablet) {
      align-items: center;
      object-fit: fill;
      margin-top: unset;
      height: 100%;
    }

    .img {
      max-height: 375px;

      @include from($widthTablet) {
        object-fit: none;
        object-position: 11vw 0;
        max-height: 558px;
      }
    }
  }
}
