@import 'styles/breakpoints';

.slider-section {
	display: grid;
	grid-template-columns: 100vw;
	min-height: 80vh;
	background-color: var(--accent-yellow);
	padding-bottom: 48px;

	@include from($widthTablet) {
		grid-template-columns: 50vw 50vw;
		padding-bottom: 0;
	}

	@include from($widthDesktopLarge) {
		min-height: 50vw;
	}

	picture {
		position: relative;
		width: 100%;
		height: 100%;

		img {
			object-position: center;
		}

		@include until($widthTablet) {
			min-height: 240px;
			height: 100%;

			img {
				object-position: top;
			}
		}
	}

	.slider-wrapper {
		position: relative;
		width: 100%;

		.slide-wrapper {
			display: inline-flex !important;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			padding: 0 clamp(var(--spacing-400), 5vw, var(--spacing-900));
		}

		.custom-pagination {
			position: absolute;
			bottom: -32px;
			right: 24px;
			display: flex;
			gap: var(--spacing-300);

			@include from($widthTablet) {
				bottom: var(--spacing-600);
				right: var(--spacing-600);
			}

			.dot {
				position: relative;
				display: block;
				border: 2px solid var(--border-high-contrast);
				background-color: transparent;
				width: 16px;
				height: 16px;
				aspect-ratio: 1;
				border-radius: 8px;
				cursor: pointer;

				&.active {
					background-color: var(--blue-800);
				}
			}
		}
	}
}
